@media only screen and (max-width: 600px) {
    .categorieSection {
        .categorieCard {
            margin-bottom: 1em;
        }
    }
}
.mainSection{
    margin-bottom: 5em;
}
.parallax{
    background-color: #f8fbfe;
}