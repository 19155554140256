// .blockquote{
//    border-left-color: #1e7e34 !important
// }
blockquote {
    background-color: #fff;
    border-left: 0.7rem solid #007bff;
    margin: 1.5em 0.7rem;
    padding: 0.5em 0.7rem;
    font-weight: 600;
}
.parallax{
    background-color: #f8fbfe;
}