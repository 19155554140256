
@media only screen and (max-width: 600px) {

    .joinComBtn{
        margin-bottom: 5px;
        align-items: center;
    }

    .comText{
       
    }
}



.imgCom{
   width: 350px;
   height: 350px;
    // border-radius: 50%;
    // border:20px solid rgb(230, 227, 227)
}
.mainCom{
}

@media only screen and (max-width: 600px) {
.imgCommunity{
    display: none;
}
}

.communityLink{
    background-color: #81b5cb;
    padding: .8rem;
    text-decoration: none;
    margin-top: 1rem;
    color: white;
}