.FaqHeaderPageSection {
    padding: 80px 0px 50px 0px !important;
}
.ftTitle {
    font-weight: bold;
}

.FaqHeaderPagePageSection {
    height: 250px;
}
.accordion-button{
    font-weight: bold !important;
}