@media only screen and (max-width: 600px) {
    .HowtodoSection {
        // background-color: lightblue;

        .HowtodoCard {
            margin-bottom: 1em;

        }
    }
}

.cardStep {
    border-radius: 20px !important;
}

.ftTitle {
    font-weight: bold;
}

.HowtodoHeaderSection {
    // background-color: aqua;
    padding: 80px 0px 50px 0px !important;
}

.HowtodoPageSection {
    height: 250px;
}

.sectitle {
    font-family: 'Maven Pro', sans-serif;
}

.ha-step-arrow {
    top: 49%;
    left: calc(100% + 20px);
    width: 100px;
    -webkit-transform: rotate(var(--ha-stepflow-direction-angle, 0));
    -ms-transform: rotate(var(--ha-stepflow-direction-angle, 0));
    transform: rotate(var(--ha-stepflow-direction-angle, 0));
    /* -webkit-transform-origin: var(--ha-stepflow-direction-origin-x) center; */
    -ms-transform-origin: var(--ha-stepflow-direction-origin-x) center;
    transform-origin: var(--ha-stepflow-direction-origin-x) center;
}

.ha-step-arrow,
.ha-step-arrow:after {
    position: absolute;
    display: inline-block;
    border-top: 1px solid #ddd;
}