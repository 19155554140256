.bannerSection{
    // background-color: blue;

}
// .boxHeaderImg{
//     margin-top: 5em;
// }
@media only screen and (max-width: 600px) {
    .bannerSection {
        // background-color: lightblue;

        .boxHeaderTitle {
            margin-top: 1em;
        }

        .boxHeaderImg {
            margin-top: 2em;

            .imgBox {
                border-radius: 10px !important;
            }
        }

    }
}

.textHeaderTitle,.nameTitle{
    font-family: 'Maven Pro', sans-serif;
}