.e-logo{
    background-image: url('Logo_e-triangle.png');
    height: 50px;
}
.nav-link{
    margin-left: 2.5rem;
    color: #000;
    font-size: 18px !important;
}
.nav-link:hover{
    color: red;

}