@media only screen and (max-width: 600px) {

    
}




* {
    font-family: 'Coda', cursive;
    font-family: 'Oxygen', sans-serif;
}

.headerStyle {
    background-color: white;
    width: 100%;
}

.bannerStyle {
    width: 100%;
    // padding: 50px 0px 50px 0px;

    .boxHeaderTitle {
        margin-top: 5em;

        .nameTitle {
            font-weight: bold;
            margin-bottom: 0.2em;
            font-size: 3.125em;
            line-height: 1.2;
        }

        .boxHeaderImg {
            margin-top: 10em;
        }

        .textHeaderTitle {
            font-weight: bold;
            margin-bottom: 0.8em;
        }

        .subTextHeader {
            line-height: 1.5em;
        }
    }

}

.productStyle {
    ul.navIco {
        list-style-type: none;
    }

    ul.navIco li {
        line-height: 2.2em;
        font-size: 1.2rem;
        font-weight: 400;
    }

    i.iCon {
        color: red;
    }
}







.sectitle {
    font-weight: bold;
}

.mainSection {
    margin-top: 5rem;
}

// .callToAction {
//     width: 100%;
//     height: 150px;
//     border-radius: 5px;
//     background-color: red;
//     padding: 5px;
//     background-image: url('african.jpg');
// }

.sellerHeaderImage {
    border-radius: 5px !important;
}

.imgCard {
    border-radius: 25px !important;
}



.mainCom{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    // border: 0.5px solid #ccc;
}