.footerStyle {
    background-color: #e81d23;
    // background-image: url("footerImg.png");
    // background-color: transparent;
    // height: 500px;
    // background-position: center;
    // /* Center the image */
    // background-repeat: no-repeat;
    // /* Do not repeat the image */
    // background-size: cover;

    ul.footerSocio {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
    }

    ul.footerSocio li {
        display: inline-block;
        margin: .7rem;
        color: white;

    }

    ul.footerNav {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
    }

    ul.footerNav li {
        margin: .7rem;
        color: white;

    }

    ul.footerContact {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
    }

    ul.footerContact li {
        margin: .7rem;
        color: white;

    }

    .eTriangle {
        color: white;
    }

    .icosocio {
        font-size: 1.2rem;
        font-weight: bold;
    }
}

.copy {
    color: #ccc;
}

.made {
    color: #f5f5f5;
    font-size: 0.8em;
}